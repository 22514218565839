import { useEffect, useState } from "react";
import LinkConfigBtnContainer from "./LinkConfigBtnContainer";
import LinkConfigHeader from "./LinkConfigHeader";
import LinkPreview from "./LinkPreview";
import InputCheckbox from "./InputCheckbox";
import { COLORS } from "../styles/utils/colors";
import { T, useTranslate } from "@tolgee/react";

const ConfigIdentity = ({ closeTab, linkData, setLinkData, initialForm }) => {
  const [form, setForm] = useState(initialForm);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslate();

  useEffect(() => {
    const {
      location,
      email,
      curp,
      phone_number,
      personal_info,
      address,
      document,
      selfie,
    } = form;
    const {
      location: initialLocation,
      email: initialEmail,
      curp: initialCurp,
      phone_number: initialPhone_number,
      personal_info: initialPersonal_info,
      address: initialAddress,
      document: initialDocument,
      selfie: initialSelfie,
    } = initialForm;

    if (
      !location &&
      !email &&
      !curp &&
      !phone_number &&
      !personal_info &&
      !address &&
      !document &&
      !selfie
    ) {
      setDisabled(() => true);
      return;
    }

    if (
      location !== initialLocation ||
      email !== initialEmail ||
      curp !== initialCurp ||
      phone_number !== initialPhone_number ||
      personal_info !== initialPersonal_info ||
      address !== initialAddress ||
      document !== initialDocument ||
      selfie !== initialSelfie
    ) {
      setDisabled(() => false);
    } else {
      setDisabled(() => true);
    }
  }, [form]);

  const saveConfig = () => {
    setLinkData((prev) => ({
      ...prev,
      identity: {
        location:
          form?.location !== prev.identity.location
            ? form.location
            : prev.identity.location,
        email:
          form?.email !== prev.identity.email
            ? form.email
            : prev.identity.email,
        curp:
          form?.curp !== prev.identity.curp ? form.curp : prev.identity.curp,
        phone_number:
          form?.phone_number !== prev.identity.phone_number
            ? form?.phone_number
            : prev.identity.phone_number,
        personal_info:
          form?.personal_info !== prev.identity.personal_info
            ? form?.personal_info
            : prev.identity.personal_info,
        address:
          form?.address !== prev.identity.address
            ? form.address
            : prev.identity.address,
        document:
          form?.document !== prev.identity.document
            ? form.document
            : prev.identity.document,
        selfie:
          form?.selfie !== prev.identity.selfie
            ? form.selfie
            : prev.identity.selfie,
      },
    }));

    closeTab();
  };

  const handleChecked = ({ target: { value, checked } }) =>
    setForm((prev) => ({
      ...prev,
      [value]: checked,
    }));

  return (
    <section className="linkPage__configuration">
      <div className="linkPage__configuration__menu">
        <div className="configIdentity">
          <header className="configIdentity__header">
            <LinkConfigHeader
              title={t("config_identity_title")}
              closeTab={closeTab}
            />
            <p className="text-2">
              <T keyName="config_identity_message" />
            </p>
          </header>
          <div className="configIdentity__sub-title">
            <h4 className="text-1--bold">
              <T keyName="config_data_title" />
            </h4>
            <p className="text-3 color--shuttle-gray">
              <T keyName="config_data_message" />
            </p>
          </div>
          <div className="configIdentity__form">
            <div className="configIdentity__form__form-group">
              <InputCheckbox
                label={t("input_geolocation")}
                handleChecked={handleChecked}
                value="location"
                checked={true}
                typographyClass="text-1"
                styles={{
                  color: form.location
                    ? COLORS.DODGER_BLUE
                    : COLORS.SHUTTLE_GRAY,
                }}
                disabled={true}
              />
              <p className="text-2 color--shuttle-gray configIdentity__warning">
                <div className="icon-error-relleno color--copperfield"></div>
                <T keyName="location_info" />
              </p>
            </div>
            <div className="configIdentity__form__form-group">
              <InputCheckbox
                label={t("input_email_description")}
                handleChecked={handleChecked}
                value="email"
                checked={form.email}
                typographyClass="text-1"
                styles={{
                  color: form.email ? COLORS.DODGER_BLUE : COLORS.SHUTTLE_GRAY,
                }}
              />
              {/* <div className="configIdentity__form__sub-group">
                <InputRadio
                  color="blue"
                  name="email_otp"
                  checked={form.email_otp}
                  handleCheck={handleChecked}
                  value="email_otp"
                />
                <p className="text-1">Validación de OTP</p>
              </div> */}
            </div>
            <div className="configIdentity__form__form-group">
              <InputCheckbox
                label={t("input_phone_description")}
                handleChecked={handleChecked}
                value="phone_number"
                checked={form.phone_number}
                typographyClass="text-1"
                styles={{
                  color: form.phone_number
                    ? COLORS.DODGER_BLUE
                    : COLORS.SHUTTLE_GRAY,
                }}
              />
              {/* <div className="configIdentity__form__sub-group">
                <InputRadio
                  color="blue"
                  name="phone_number_otp"
                  checked={form.phone_number_otp}
                  handleCheck={handleChecked}
                  value="phone_number_otp"
                />
                <p className="text-1">Validación de OTP vía SMS</p>
              </div> */}
            </div>
            <div className="configIdentity__form__form-group">
              <InputCheckbox
                label={t("input_personal_data")}
                handleChecked={handleChecked}
                value="personal_info"
                checked={form.personal_info}
                typographyClass="text-1"
                styles={{
                  color: form.personal_info
                    ? COLORS.DODGER_BLUE
                    : COLORS.SHUTTLE_GRAY,
                }}
              />
              <p className="text-2 color--shuttle-gray">
                <T keyName="personal_data_info" />
              </p>
            </div>
            <InputCheckbox
              label="CURP"
              handleChecked={handleChecked}
              value="curp"
              checked={form.curp}
              typographyClass="text-1"
              styles={{
                color: form.curp ? COLORS.DODGER_BLUE : COLORS.SHUTTLE_GRAY,
              }}
            />
            <InputCheckbox
              label={t("input_address")}
              handleChecked={handleChecked}
              value="address"
              checked={form.address}
              typographyClass="text-1"
              styles={{
                color: form.address ? COLORS.DODGER_BLUE : COLORS.SHUTTLE_GRAY,
              }}
            />
            <div className="configIdentity__form__form-group">
              <InputCheckbox
                label={t("input_document")}
                handleChecked={handleChecked}
                value="document"
                checked={form.document}
                typographyClass="text-1"
                disabled={true}
                styles={{
                  color: form.document
                    ? COLORS.DODGER_BLUE
                    : COLORS.SHUTTLE_GRAY,
                }}
              />
              <p className="text-2 color--shuttle-gray">
                <T keyName="document_message" />
              </p>
            </div>
            <InputCheckbox
              label={t("input_liveness_check")}
              handleChecked={handleChecked}
              value="selfie"
              checked={form.selfie}
              typographyClass="text-1"
              disabled={true}
              styles={{
                color: form.selfie ? COLORS.DODGER_BLUE : COLORS.SHUTTLE_GRAY,
              }}
            />
          </div>
        </div>

        <LinkConfigBtnContainer
          cancelBtnConfig={{ text: t("cancel_button"), handleClick: closeTab }}
          saveBtnConfig={{
            text: t("save_changes_button"),
            handleClick: saveConfig,
          }}
          enableSave={!disabled}
        />
      </div>
      <LinkPreview linkData={linkData} identityPreview={form} />
    </section>
  );
};
export default ConfigIdentity;
